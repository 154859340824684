import React from 'react';
import data from '../../utils/TermsAndConditions.json';
import { Box, Container, Typography } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Box my={6}>
      <Container>
      <Typography sx={{ textAlign: 'center' }} variant='h3' mb={4}>Terms And Conditions</Typography>
        {data.map((item, index) => (
          <Box key={item.title} textAlign="justify">
            {index !== 0 && <Box mt={2} />}
            <Typography variant="h5" component="span">
              {item.title}:
            </Typography>{' '}
            <Typography variant="body1" component="span">
              {item.description}
            </Typography>
          </Box>
        ))}
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
